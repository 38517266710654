// transition
@mixin transition($transition...) {
	-moz-transition: $transition;
	-o-transition: $transition;
	-webkit-transition: $transition;
	transition: $transition;
}

//box shadow
@mixin box-shadow($shadow) {
	-moz-box-shadow: $shadow;
	-o-box-shadow: $shadow;
	-webkit-box-shadow: $shadow;
	box-shadow: $shadow;
}

// transform
@mixin transform($transform...) {
	-webkit-transform: $transform;
	-ms-transform: $transform; // IE9 only
	-o-transform: $transform;
	transform: $transform;
}

// backface
@mixin backface-visibility($visibility...) {
	-webkit-backface-visibility: $visibility;
	-moz-backface-visibility: $visibility;
	-ms-backface-visibility: $visibility;
	backface-visibility: $visibility;
}

//   appearance
@mixin appearance() {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

// reset margin and padding
%reset {
	margin: 0;
	padding: 0;
}
