.btn-logout {
	padding: 10px 5px 10px 20px;
	color: $white;
	font-size: $font-size;
	margin: 10px 0;
	font-weight: 400;
	position: relative;

	&:hover {
		color: $white;
	}

	img {
		margin-right: 15px;
		height: 24px;

		@media screen and (max-width: 768px) {
			height: 19px;
		}
	}
}

// collapsed sidebar styles

.collapsed {
	width: 64px !important;

	@media screen and (max-width: 768px) {
		left: -65px !important;
	}

	.nav-link span,
	.btn-logout span {
		left: -90px;
		opacity: 0;
	}

	.aside-header img {
		height: 57px !important;
	}
}

.sidebar-list::-webkit-scrollbar {
	display: none;  /* Safari and Chrome */
}

#sidebar {
	background-color: $theme-dark-blue;
	background-image: url(../img/layer.png);
	background-repeat: no-repeat;
	background-position: bottom;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	z-index: 1000;
	width: 300px;

	@include transition(.4s ease);

	.control-sidebar {
		position: absolute;
		right: -29px;
		bottom: 0;
		background: transparent;
		border: none;

		@media screen and (max-width: 991px) {
			right: -33px;
		}

		&:focus {
			outline: none;
		}

		img {
			@media screen and (max-width: 991px) {
				height: 100px;
			}
		}
	}

	.aside-header {
		padding: 15px 0;
		border-bottom: 1px solid rgba(255, 255, 255, .3);
		text-align: center;

		img {
			height: 80px;
		}
	}

	.sidebar-list {
		height: calc(100vh - 110px);
		overflow: auto;
		-webkit-overflow-scrolling: touch;
		padding-bottom: 30px;
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */

		ul {
			list-style-type: none;
			width: 100%;

			@extend %reset;

			li {
				.nav-link {
					display: block;
					padding: 15px 5px 15px 20px;
					color: $white;
					font-size: $font-size;
					margin-top: 1px;
					font-weight: 400;
					cursor: pointer;
					white-space: nowrap;
					font-family: sans-serif, 'montserrat-light';

					span {
						list-style: lower-alpha;
						position: relative;
						left: 0;

						@include transition(.2s ease);
					}

					@include transition(.2s ease);

					img {
						height: 23px;
						width: 24px;
						margin-right: 15px;
						opacity: .4;

						@include transition(.2s ease);

						@media screen and (max-width: 768px) {
							height: 18px;
							width: 19px;
						}
					}

					&:hover {
						background-color: darken($theme-dark-blue, 5%);

						img {
							opacity: 1;
						}
					}
				}

				.nav-link.active {
					background-color: darken($theme-dark-blue, 5%);

					img {
						opacity: 1;
					}
				}
			}
		}
	}
}

#sidebar.collapsed {
	.control-sidebar {
		@media screen and (max-width: 991px) {
			right: -50px !important;
		}
	}
}
