#map-transport {
	.info-window {
		min-height: 150px;

		.form-group {
			transition: 0.2s ease all;
			border-color: #dadada;
			border-radius: 4px;
			color: #343748;
			font-size: 14px;
			font-weight: 300;
			height: 50px;
		}

		.form-control {
			display: block;
			width: 100%;
			height: calc(1.5em + 0.75rem + 2px);
			padding: 0.375rem 0.75rem;
			font-size: 1rem;
			font-weight: 400;
			line-height: 1.5;
			color: #495057;
			background-color: #fff;
			background-clip: padding-box;
			border: 1px solid #ced4da;
			border-radius: 0.25rem;
			transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
		}
	}
}
