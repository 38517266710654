#legislation {
	.category {
		&-link {
			//border-radius: 5px;
			padding: 10px 60px 10px 15px;
			//border: 1px solid #b3b7bd;
			background-color: $white;
			background-image: url(../img/done-black.svg);
			background-repeat: no-repeat;
			background-position: 0% 55%;
			display: block;
			width: 100%;
			color: #33466e !important;
			font-size: 18px;
			font-weight: 400;
			text-transform: uppercase;
			margin-bottom: 20px;
			cursor: pointer;

			.category-title {
				margin-left: 15px;
			}

			@media screen and (max-width: 991px) {
				padding: 15px 60px 15px 15px;
				font-size: 14px;
			}

			&:hover {
				text-decoration: none;
				color: #33466e;
			}
		}

		&-children-collapse {
			padding-left: 5%;
			overflow: hidden;
			max-height: 0;
			transition: max-height 0.25s ease-out;

			&.active {
				max-height: 100%;
				transition: max-height 0.35s ease-in;
			}
		}
	}

	.law {
		&-title {
			line-height: 25px;
		}

		.left-column {
			margin-bottom: 10px;

			.category-title {
				background-color: #1d4f87;
				border-radius: 2px;
				color: #fff !important;
				font-size: 12px;
				padding: 5px;
				word-break: break-word;
				margin-bottom: 0 !important;
				text-align: center;
				line-height: 25px;
			}

			.number {
				text-align: right;
				font-weight: bold;
				color: #fff !important;
				background-color: #a01e1e;
				font-size: 14px;
				padding: 5px;
				border-radius: 2px;
				line-height: 25px;
			}
		}
	}
}
