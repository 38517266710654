.statistic-row {
	margin-bottom: 20px;

	.statistic-desc {
		p {
			color: #17233b;
			font-size: 18px;
			font-weight: 500;
			margin: 0;
		}

		span {
			color: #9ca3ad;
			font-size: 20px;
			font-weight: 500;
		}
	}

	.statistic-progress-bar {
		width: 152px;
		height: 4px;
		background-color: #e4e5ed;
		border-radius: 2px;

		.progress {
			height: 100%;
			border-radius: 2px;
		}
	}
}
