.objects-link {
	display: block;
	padding: 50px 50px 25px;
	text-align: center;
	margin-bottom: 20px;
	min-height: 300px;

	@include box-shadow(0 10px 50px rgba(0, 123, 255, 0.15));

	border-radius: 10px;
	background-color: $white;

	&:hover {
		text-decoration: none;
	}

	span {
		display: block;
		color: $text-dark-blue;
		font-size: 18px;
		font-weight: 600;
		letter-spacing: -0.5px;
		margin-top: 30px;
		line-height: normal;
	}

	.count {
		color: #33466e;
		font-size: 30px;
		font-weight: 600;
		letter-spacing: -0.36px;
		margin-bottom: 3px;
		margin-top: 20px;
	}

	@media screen and (max-width: 991px) {
		min-height: auto;
	}
}

.objects-wrapper {
	position: relative;
	height: 100%;
	min-height: 700px;

	@media screen and (max-width: 991px) {
		min-height: 500px;
	}
}

.add-form {
	label {
		color: #565d6f;
		font-size: 14px;
	}
}

.see-on-map {
	color: $theme-blue;
	cursor: pointer;
}

.acts-link {
	border-radius: 5px;
	padding: 20px 60px 15px 15px;
	border: 1px solid #b3b7bd;
	background-color: $white;
	background-image: url(../img/next.svg);
	background-repeat: no-repeat;
	background-position: 97% 50%;
	display: block;
	width: 100%;
	color: #33466e;
	font-size: 18px;
	font-weight: 400;
	text-transform: uppercase;
	margin-bottom: 20px;

	@media screen and (max-width: 991px) {
		padding: 15px 60px 15px 15px;
		font-size: 14px;
	}

	&:hover {
		text-decoration: none;
		color: #33466e;
	}
}

.statistics {
	margin-left: 15px;
	padding: 50px 0;

	@media screen and (max-width: 768px) {
		margin-left: 0;
	}

	h4 {
		color: #33466e;
		font-size: 28px;
		font-weight: 500;
		margin-bottom: 30px;
	}
}

.statistic-link {
	margin: 0;
	margin-bottom: 20px;

	span {
		color: #565d6f;
		font-size: 16px;
		font-weight: 400;
		margin-top: 20px;
	}

	.st-pic {
		width: 70px;
		height: 70px;
		border-radius: 50%;
		background-color: #eaf7ff;
		display: inline-block;
	}
}

.useful-links {
	padding: 0;
	margin: 0;
	list-style-type: none;

	li {
		display: inline-block;
		padding: 0 20px;
		border-right: 1px solid $border-color;

		&:last-child {
			border-right: none;
		}
	}

	.useful-links-item {
		color: $black;
		filter: grayscale(100%);
		opacity: 0.4;
		text-decoration: none;

		&.active {
			filter: grayscale(0);
			opacity: 1;
		}
	}
}
