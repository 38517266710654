#karabakh {
	.category {
		&_title {
			span {
				color: $text-dark-blue;
				font-size: 24px;
				font-weight: 600;
				letter-spacing: -0.5px;
				line-height: normal;
			}
		}

		&_link {
			display: block;
			//padding: 50px 50px 25px;
			//text-align: center;
			margin-bottom: 20px;

			@include box-shadow(0 10px 50px rgba(0, 123, 255, 0.15));

			border-radius: 10px;
			background-color: $white;

			&:hover {
				text-decoration: none;
			}

			.name {
				span {
					display: block;
					color: $text-dark-blue;
					font-size: 18px;
					font-weight: 600;
					letter-spacing: -0.5px;
					line-height: normal;
				}
			}

			.count {
				color: #33466e;
				font-size: 30px;
				font-weight: 600;
				letter-spacing: -0.36px;
				margin-bottom: 3px;
				margin-top: 20px;
			}

			@media screen and (max-width: 991px) {
				min-height: auto;
			}
		}
	}

	.list {
		&_link {
			display: block;
			padding: 24px;
			margin-bottom: 20px;

			@include box-shadow(0 10px 50px rgba(0, 123, 255, 0.15));

			border-radius: 10px;
			background-color: $white;

			img {
				width: 100%;
				max-height: 170px;
			}

			a:hover {
				text-decoration: none;
			}

			.content {
				&_title {
					display: block;
					color: $text-dark-blue;
					letter-spacing: -0.5px;
					line-height: normal;

					&_inline {
						font-size: 14px;
						color: $text-dark-blue;
					}
				}

				&_body {
					display: block;
					color: #33466e;
					//font-size: 18px;
					//font-weight: 600;
					letter-spacing: -0.5px;
					line-height: normal;

					&_inline {
						color: $text-dark-blue;
						font-size: 10px;
					}
				}
			}

			@media screen and (max-width: 991px) {
				min-height: auto;
			}
		}
	}

	.detail {
		.slider {
			width: 100%;
			margin-bottom: 24px;
			overflow: hidden;

			img {
				width: 100%;
			}
		}

		.title {
			font-size: 20px;
			line-height: 24.83px;
			margin-bottom: 16px;
		}

		.body {
			font-size: 14px;
			line-height: 22px;
		}

		.list {
			&_link {
				display: block;
				padding: 24px;
				margin-bottom: 20px;

				@include box-shadow(0 10px 50px rgba(0, 123, 255, 0.15));

				border-radius: 10px;
				background-color: $white;

				img {
					width: 100%;
				}

				a:hover {
					text-decoration: none;
				}

				.content {
					.title {
						font-size: 14px;
						line-height: 16px;
						margin-bottom: 4px;
					}

					.body {
						font-size: 10px;
						line-height: 14px;
					}

					.time {
						color: #9296aa;
						font-size: 16px;
					}
				}

				@media screen and (max-width: 991px) {
					min-height: auto;
				}
			}
		}
	}

	.heroes {
		border-top-right-radius: 15px;
		border-top-left-radius: 15px;

		th,
		td {
			border-top-right-radius: 15px;
			border-top-left-radius: 15px;
			vertical-align: middle;

			p {
				max-height: 120px;
				overflow: hidden;
				overflow-y: auto;
			}

			.hero_img {
				width: 80px;
			}
		}
	}

	.victory {
		.youtube {
			margin-top: 50px;
		}
	}
}
