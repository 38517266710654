@keyframes modalFade {
	from {
		@include transform(translateY(-50%));

		/* Netscape */
		-moz-opacity: 0;

		/* Safari 1.x */
		-khtml-opacity: 0;

		/* Good browsers */
		opacity: 0;
	}

	to {
		@include transform(translateY(0));

		/* Netscape */
		-moz-opacity: 1;

		/* Safari 1.x */
		-khtml-opacity: 1;

		/* Good browsers */
		opacity: 1;
	}
}

.modal-custom {
	position: fixed;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 1000;
	overflow: auto;
	padding: 50px 0;
	-ms-overflow-style: none;  /* Internet Explorer 10+ */
	scrollbar-width: none;  /* Firefox */

	.modal-custom-header {
		padding-bottom: 20px;

		h4 {
			color: #33466e;
			font-size: 30px;
			font-weight: 600;
		}
	}

	.modal-custom-inner {
		background-color: $white;
		padding: 40px;
		border-radius: 10px;
		animation-name: modalFade;
		animation-duration: .5s;
		position: relative;

		@include box-shadow(0 0 30px rgba(0, 0, 0, 0.1));

		@media screen and (max-width: 768px) {
			padding: 15px;
		}
	}
}

.modal-success {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 10px;
	background-color: rgba(255, 255, 255, 0.7);
	z-index: 1;

	p {
		color: $theme-dark-blue;
		font-size: 32px;
		margin: 0;
		margin-top: 40px;
	}
}

.success-message {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(255, 255, 255, 0.9);
	z-index: 1000;
	overflow: auto;
	padding: 50px 0;
	-ms-overflow-style: none;  /* Internet Explorer 10+ */
	scrollbar-width: none;  /* Firefox */

	.message-content {
		position: absolute;
		top: 50%;
		left: 50%;
		text-align: center;

		@include transform(translate(-50%, -50%));
	}

	p {
		color: $theme-dark-blue;
		font-size: 28px;
		margin: 0;
		margin-top: 40px;
	}
}
