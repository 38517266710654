#notify-drop {
	.notify-drop-title {
		border-bottom: 1px solid #e2e2e2;
		padding: 5px 15px 10px 15px;
	}

	.notification-badge {
		position: relative;
		cursor: pointer;

		.pulse-button {
			position: relative;
			top: 50%;
			left: 50%;
			margin-top: -31px;
			line-height: 24px;
			display: block;
			width: 24px;
			height: 24px;
			font-size: 12px;
			font-weight: light;
			font-family: 'Trebuchet MS', sans-serif;
			text-transform: uppercase;
			text-align: center;
			color: #fff;
			border: none;
			border-radius: 50%;
			background: #e93939;
			cursor: pointer;
			box-shadow: 0 0 0 0 rgba(#e93939, .5);
			-webkit-animation: pulse 3s infinite;
			animation: pulse 3s infinite;
			animation-delay: 10s;
		}

		.pulse-button:hover {
			-webkit-animation: none;
			animation: none;
		}
	}

	.drop-content {
		min-height: 280px;
		max-height: 280px;
		overflow-y: scroll;

		&::-webkit-scrollbar-track {
			background-color: #f5f5f5;
		}

		&::-webkit-scrollbar {
			width: 8px;
			background-color: #f5f5f5;
		}

		&::-webkit-scrollbar-thumb {
			background-color: #ccc;
		}

		> .item {
			border-bottom: 1px solid #e2e2e2;
			padding: 10px 0 5px 0;

			&:nth-child(2n+0) {
				background-color: #fafafa;
			}

			&::after {
				content: "";
				clear: both;
				display: block;
			}

			&:hover {
				background-color: #fcfcfc;
			}

			&:last-child {
				border-bottom: none;
			}

			.notify-img {
				float: left;
				display: inline-block;
				width: 45px;
				height: 45px;
				margin: 0 0 8px 0;
			}

			.title {
				font-size: 12px;
				font-weight: normal;
				margin-right: 5px;
			}

			font-weight: bold;
			font-size: 11px;

			hr {
				margin: 5px 0;
				width: 70%;
				border-color: #e2e2e2;
			}

			.pd-l0 {
				padding-left: 0;
			}

			.body {
				font-size: 11px;
				color: #666;
				font-weight: normal;
				margin: 3px 0;
			}

			.time {
				font-size: 10px;
				font-weight: 600;
				top: -6px;
				margin: 8px 0 0 0;
				padding: 0 3px;
				border: 1px solid #e2e2e2;
				position: relative;
				background-image: linear-gradient(#fff, #f2f2f2);
				display: inline-block;
				border-radius: 2px;
				color: #b97745;

				&:hover {
					background-image: linear-gradient(#fff, #fff);
				}
			}
		}
	}

	.allRead {
		margin-right: 7px;
	}

	.rIcon {
		float: right;
		color: #999;

		&:hover {
			color: #333;
		}
	}

	.notify-drop-footer {
		border-top: 1px solid #e2e2e2;
		bottom: 0;
		position: relative;
		padding: 8px 15px;

		a {
			color: #777;
			text-decoration: none;

			&:hover {
				color: #333;
			}
		}
	}
}

@keyframes pulse {
	70% {
		// @include transform(scale(1));

		box-shadow: 0 0 0 50px rgba(#5a99d4, 0);
	}

	100% {
		// @include transform(scale(.9));

		box-shadow: 0 0 0 0 rgba(#5a99d4, 0);
	}
}

@-webkit-keyframes pulse {
	70% {
		// @include transform(scale(1));

		box-shadow: 0 0 0 50px rgba(#5a99d4, 0);
	}

	100% {
		// @include transform(scale(.9));

		box-shadow: 0 0 0 0 rgba(#5a99d4, 0);
	}
}
