#header {
	padding: 30px 0;
	background-color: $white;
	border-bottom: 1px solid #d8d8d8;
	min-height: 110px;
	margin-bottom: 20px;

	@media screen and (max-width: 768px) {
		padding: 5px 0;
		min-height: auto;
	}

	.profile-pic {
		width: 50px;
		height: 50px;
		border-radius: 50%;
		overflow: hidden;
		background-image: url(../img/profile-pic.svg);
		background-repeat: no-repeat;
		background-size: contain;
	}

	.profile-name {
		p {
			margin: 0;
			color: $theme-blue;
		}
	}
}

.search-box-wrapper {
	@media screen and (max-width: 768px) {
		.show,
		.hide {
			@include transition(.5s ease);

			margin-top: 30px;
		}

		.show {
			display: block;
		}

		.hide {
			display: none;
		}
	}
}
