
.table-wrapper {
	overflow: auto;

	/* width */
	&::-webkit-scrollbar {
		display: none;
	}
}

.table-custom {
	.company-logo {
		width: 50px;
		height: 50px;
		border-radius: 50%;
		background-color: #d8d8d8;
		background-repeat: no-repeat;
		background-size: cover;
		display: inline-block;

		@media screen and (max-width: 991px) {
			display: none;
		}
	}

	tbody tr {
		border-bottom: 1px solid $border-color;
	}

	tbody tr:hover {
		@include box-shadow(0 0 20px rgba(46, 107, 255, 0.2));
		@include transition(.1s ease);
	}

	th,
	td {
		color: #565d6f;
		font-weight: 500;
		border: none;
	}

	thead th {
		border-width: 1px;
		font-size: $font-size;
		white-space: nowrap;

		@media screen and (max-width: 991px) {
			font-size: 14px;
		}
	}

	td {
		padding: 20px 9px;
		font-size: 14px;
		font-weight: 300;
		vertical-align: middle;

		@media screen and (max-width: 991px) {
			font-size: 12px;
		}
	}

	.table-link {
		text-decoration: none;
		color: $theme-blue;
	}

	.table-edit-item {
		background-color: transparent;
		border: none;
		white-space: nowrap;
		font-size: 14px;
		font-weight: 300;
	}
}

.social-links {
	list-style-type: none;
	position: relative;
	z-index: 100;
	margin-bottom: 30px;

	@media screen and (max-width: 768px) {
		margin-bottom: 0;
	}

	@extend %reset;

	li {
		display: inline-block;

		@media screen and (max-width: 300px) {
			display: block;
		}

		a {
			padding: 10px 20px;
			color: #a3a6b1;
			font-size: $font-size;
			font-weight: 500;
			text-decoration: none;
			border: 1px solid $border-color;
			background-color: $white;
			display: block;
		}

		&:first-child > a {
			border-top-left-radius: 4px;
			border-bottom-left-radius: 4px;
		}

		&:last-child > a {
			border-top-right-radius: 4px;
			border-bottom-right-radius: 4px;
		}

		.active {
			background-color: #eaf0ff;
			color: $theme-blue;
			border-color: transparent;
		}
	}
}

.detail-info {
	padding: 0;
	margin: 0;

	li {
		display: inline-block;
		color: #33466e;
		font-size: 15px;
		font-weight: 300;
		letter-spacing: -0.3px;
		padding: 0 20px;
		border-right: 1px solid #d0d5da;

		@media screen and (max-width: 991px) {
			border-right: none;
			margin-bottom: 10px;
			padding: 0;
			display: block;
		}

		img {
			margin-right: 10px;
		}
	}

	li:first-child {
		padding-left: 0;
	}

	li:last-child {
		border-right: none;
	}
}
