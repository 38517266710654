.fc-icon-chevron-left::before {
	color: #b3b7bd;
}

.fc-icon-chevron-right::before {
	color: #b3b7bd;
}

.fc-button {
	background-color: transparent;
	border: 1px solid #b3b7bd;
	color: #b3b7bd;

	&:hover {
		background-color: #2e6bff;
		border-color: #2e6bff;
		color: #fff;

		.fc-icon-chevron-left::before {
			color: #fff;
		}

		.fc-icon-chevron-right::before {
			color: #fff;
		}
	}
}

.fc-unthemed td.fc-today {
	background-color: #e7e7ef;
}

.fc-toolbar {
	@media screen and (max-width: 991px) {
		display: block !important;
	}
}

.fc-toolbar h2 {
	@media screen and (max-width: 991px) {
		font-size: 18px !important;
	}
}
