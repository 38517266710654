.task-ownership {
	@extend .d-flex;
	@extend .align-items-center;

	img {
		width: 54px;
		height: 54px;
		border-radius: 50%;
		object-fit: cover;
		margin-right: 10px;
	}

	&-pos {
		h3 {
			font-size: 20px;
			color: $theme-dark-blue;
			margin: 0;
		}

		span {
			font-size: 16px;
			color: $light-gray;
			margin: 0;
		}
	}
}

.dropdown-wrapper {
	position: relative;

	.dropdown {
		@extend %reset;

		min-width: 100%;
		white-space: nowrap;
		position: absolute;
		top: 100%;
		left: 0;
		z-index: 100;
		list-style-type: none;
		padding: 20px;
		margin-top: 10px;
		display: none;

		@include box-shadow(0 2px 20px rgba(207, 203, 220, 0.5));

		border-radius: 10px;
		background-color: $white;

		.dropdown-link {
			background-color: transparent;
			border: none;
			color: $light-gray;
			font-size: 16px;
		}
	}

	.dropdown-for-component {
		@media screen and (min-width: 991px) {
			min-width: 350px;
			left: auto;
			right: 0;
		}
	}

	.dropdown.show {
		display: block;
	}
}

.task-box {
	padding: 20px 0;

	.task-content {
		padding: 25px;
		background-color: $theme-blue-2;
		border-radius: 10px;

		@media screen and (max-width: 991px) {
			padding: 15px;
		}

		p {
			color: $white;
			margin: 0;
			font-size: 18px;

			@media screen and (max-width: 991px) {
				font-size: 15px;
			}
		}
	}
}

.comment-box {
	margin-top: 100px;

	@media screen  and (max-width: 991px) {
		margin-top: 20px;
	}

	.comments {
		margin-bottom: 70px;

		.comment-text {
			padding: 20px;
			margin: 20px 0;
			max-width: 800px;

			@include box-shadow(0 2px 20px rgba(207, 203, 220, 0.5));

			border-radius: 10px;
			background-color: $white;

			h5 {
				font-size: 20px;
				color: $light-gray;
			}

			p {
				margin: 0;
				font-size: 16px;
				color: $light-gray;
			}

			time {
				font-size: 13px;
				display: block;
				text-align: right;
				color: $light-gray;
			}
		}

		.uploaded-files {
			@extend .d-flex;
			@extend .align-items-center;

			img {
				max-width: 330px;
				object-fit: cover;
				margin: 0 10px;
			}
		}
	}

	.comment-form-box {
		position: relative;

		.upload-file {
			width: 25px;
			height: 25px;
			background-image: url(../img/file-upload.svg);
			background-repeat: no-repeat;
			background-size: contain;
			position: absolute;
			top: 50%;
			left: 20px;
			z-index: 3;

			@include transform(translateY(-50%));

			input[type='file'] {
				opacity: 0;
				width: 25px;
				height: 25px;
			}
		}

		.send-comment {
			position: absolute;
			top: 50%;
			right: 20px;

			@include transform(translateY(-50%));
		}

		.comment-input {
			width: 100%;
			height: 54px;
			border: none;
			text-indent: 20px;
			padding: 0 50px 0 10px;

			&:focus {
				outline: none;
			}

			@include box-shadow(0 2px 20px rgba(222, 221, 239, 0.5));

			border-radius: 8px;
			background-color: $white;
		}
	}
}
