.icon {
	background-repeat: no-repeat;
	background-position: center;
	display: inline-block;
	width: 24px;
	height: 24px;
}

.icon-plus {
	background-image: url(../img/plus.svg);
}

.icon-edit {
	background-image: url(../img/edit-icon.svg);
	background-size: contain;
}

.icon-delete {
	background-image: url(../img/trash.svg);
	background-size: contain;
}

.icon-delete-2 {
	background-image: url(../img/delete.svg);
	background-size: contain;
}

.icon-print {
	background-image: url(../img/print-icon.svg);
	background-size: contain;
}

.icon-link {
	background-image: url(../img/external-link.svg);
	background-size: contain;
}

.icon-close-modal {
	width: 20px;
	height: 20px;
	background-image: url(../img/close.svg);
	background-size: contain;
	cursor: pointer;
}

.icon-edit-n {
	width: 18px;
	height: 18px;
	background-image: url(../img/edit.svg);
	background-size: contain;
}

.icon-detail {
	background-image: url(../img/detail.svg);
	background-size: contain;
}

.icon-edit-gray {
	background-image: url(../img/edit-gray.svg);
}
