// starting styling...

html * {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	letter-spacing: normal !important;
}

body,
html {
	@extend .h-100;

	font-family: sans-serif, 'montserrat-regular';
}

#root,
.app,
.wrapper {
	@extend .h-100;
}

#token {
	display: none;
}

.cur-point {
	cursor: pointer;
}

.wrapper {
	position: relative;
	top: 0;
	height: 100vh;
}

.main-panel {
	position: relative;
	float: right;
	width: calc(100% - 300px);
	min-height: 100%;
	padding-bottom: 50px;

	@include transition(.4s ease);

	background-color: $panel-color;

	@media screen and (max-width: 991px) {
		width: 100%;
	}
}

.expanded {
	width: calc(100% - 64px) !important;

	@media screen and (max-width: 768px) {
		width: 100% !important;
	}
}

.scrolled {
	max-height: 700px;
	overflow: auto;
}

/* width */
.scrolled::-webkit-scrollbar {
	width: 4px;
}

/* Track */
.scrolled::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px $border-color;
	border-radius: 10px;
}

/* Handle */
.scrolled::-webkit-scrollbar-thumb {
	background: $theme-blue;
	border-radius: 10px;
}

/* Handle on hover */
.scrolled::-webkit-scrollbar-thumb:hover {
	background: $theme-blue;
}

.over-hide {
	overflow: hidden;
}

// btn theme
.btn-theme {
	background-color: $theme-blue;
	color: $white;
	font-weight: 500;
	height: 50px;
	position: relative;

	&:hover {
		background-color: darken($theme-blue, 5%);
		color: $white;
	}
}

// form styles
.form-group-custom {
	margin-bottom: 20px;

	@media screen and (max-width: 991px) {
		margin-bottom: 10px;
	}

	label {
		color: #b9b8c8;
		font-size: 18px;
		font-weight: 500;
	}

	.form-control {
		@include transition(.2s ease all);

		border-color: $border-color;
		border-radius: 4px;
		color: #343748;
		font-size: 14px;
		font-weight: 300;
		height: 50px;

		&::placeholder {
			color: #c5c5d4;
		}

		&:focus {
			box-shadow: none;
			border-color: $theme-blue;

			&::placeholder {
				color: lighten(#c5c5d4, 8%);
			}
		}
	}

	.has-error {
		border-color: red !important;
	}

	textarea.form-control {
		height: 150px;
		resize: none;
	}

	.search-input {
		background-image: url(../img/search.svg);
		background-position: 96% 50%;
		background-repeat: no-repeat;
		padding-right: 50px;

		@media screen and (max-width: 768px) {
			background-image: none;
		}
	}

	.selectbox {
		-webkit-appearance: none;
		-moz-appearance: none;
		background-image: url(../img/arrow.svg);
		background-position: 96% 50%;
		background-repeat: no-repeat;
		padding-right: 50px;
	}

	.file-name {
		min-height: 50px;
		border: 1px solid $border-color;
		border-radius: 4px;
		color: #a3a6b1;
		font-size: 15px;
		font-weight: 300;
		padding: 20px;

		@extend .over-hide;
	}

	.file-item {
		position: relative;

		img {
			width: 110px;
			height: 66px;
			object-fit: cover;
		}

		.btn-remove {
			position: absolute;
			right: -12px;
			top: -9px;
			width: 30px;
			height: 30px;
			border-radius: 50%;
			font-size: 14px;
		}
	}

	.file-button {
		width: 100%;
		height: 50px;
		border-radius: 5px;
		border: 1px solid $theme-blue;
		background-color: $white;
		color: $theme-blue;
		font-size: 15px;
		font-weight: 500;
		line-height: 33px;

		& + input {
			position: absolute;
			left: 15px;
			height: 100%;
			opacity: 0;
		}
	}
}

// auth components styles
.auth-wrapper {
	@extend .h-100;

	background-image: url('../img/map.png');
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
	overflow: auto;

	&::before {
		background-color: #243c9b;
		content: '';
		height: 100%;
		left: 0;
		opacity: 0.6;
		position: fixed;
		top: 0;
		width: 100%;
	}
}

.auth-header {
	padding: 30px 0 5px;

	&-logo {
		height: 74px;

		@media screen and (max-width: 991px) {
			height: 50px;
		}
	}
}

.auth-box {
	background-color: $white;
	position: relative;
	z-index: 2;
	border-radius: 50px 4px 4px 50px;
	padding: 80px;

	@media screen and (max-width: 991px) {
		border-radius: 5px;
		padding: 25px;
		width: 90%;
		margin: 25px auto;
	}

	.auth-form-wrapper {
		position: relative;
		z-index: 5;
	}

	&::after,
	&::before {
		@include transform(translateY(-50%));

		background-color: $white;
		border-radius: 50px 4px 4px 50px;
		content: '';
		opacity: 0.5;
		position: absolute;
		top: 50%;
		width: 100%;

		@media screen and (max-width: 991px) {
			content: none;
		}
	}

	&::before {
		height: 96%;
		left: -15px;
		z-index: 1;
	}

	&::after {
		height: 93%;
		left: -30px;
		z-index: 0;
	}

	&-title {
		color: #343748;
		font-size: 38px;
		font-weight: 600;
		margin: 0;
	}
}

.forget-password {
	color: #8e8da9;
	display: block;
	font-size: 16px;
	text-decoration: none;

	&:hover {
		color: darken(#8e8da9, 5%);
		text-decoration: none;
	}
}

.whitebox {
	border-radius: 10px;
	background-color: $white;
	// min-height: 100vh;
	// padding-top: 85px;
	padding-bottom: 30px;
	position: relative;
	margin-left: 15px;

	@media screen and (max-width: 768px) {
		margin-left: 0;
	}

	@include box-shadow(0 0 30px rgba(0, 0, 0, 0.1));

	&-nav {
		@extend %reset;

		list-style-type: none;

		li {
			display: inline-block;

			a {
				color: #9ca3ad !important;
				font-size: 18px;
				font-weight: 500;
				display: block;
				padding: 20px 5px;
				margin-right: 30px;
				text-decoration: none;

				@include transition(.2s ease);

				border-bottom: 3px solid transparent;
				user-select: none;

				&:hover {
					border-color: $theme-blue;
				}
			}

			.active {
				border-color: $theme-blue;
			}
		}
	}

	article {
		margin-bottom: 50px;

		h5,
		p {
			color: $light-gray;
		}

		h5 {
			font-size: 18px;
		}

		p {
			font-size: 16px;
			margin-bottom: 10px;

			@media screen and (max-width: 991px) {
				font-size: 14px;
			}

			label {
				width: 100px;
				margin: 0;
			}
		}

		ul {
			padding: 0;
			margin: 0;
			column-count: 3;
			list-style-type: none;

			@media screen and (max-width: 991px) {
				column-count: 2;
			}

			li {
				color: #33466e;
				font-size: 18px;
				font-weight: 300;
				letter-spacing: -0.19px;
				line-height: 30px;
				margin-right: 10px;

				@media screen and (max-width: 991px) {
					font-size: 14px;
					line-height: 24px;
				}

				&::before {
					content: "•";
					color: $theme-blue;
					margin-right: 20px;
				}
			}
		}
	}

	.clr_blue {
		h4,
		p {
			color: #33466e !important;
			font-size: 18px;
			letter-spacing: -0.3px;
			line-height: 33px;

			@media screen and (max-width: 991px) {
				font-size: 14px;
				line-height: 24px;
			}

			b {
				font-weight: 600;
			}
		}

		p {
			font-weight: 300;
		}

		h4 {
			font-weight: 600;
		}
	}

	&-header {
		padding: 25px 30px;
		// position: absolute;
		width: 100%;
		top: 0;
		left: 0;

		@media screen and (max-width: 991px) {
			padding: 15px;
		}

		.whitebox-title {
			// padding-top: 30px;

			h4 {
				color: #33466e;
				font-size: 28px;
				font-weight: 500;
				letter-spacing: -0.3px;
				margin: 0;

				@media screen and (max-width: 991px) {
					font-size: 20px;
				}
			}

			h6 {
				color: #9ca3ad;
				font-size: 18px;
				font-weight: 500;
				margin: 0;
			}

			p {
				color: $light-gray;
				font-size: 18px;
				font-weight: 300;
				letter-spacing: -0.3px;
				text-decoration: underline;
				margin: 0;
				cursor: pointer;
			}

			li {
				a {
					color: #9296aa;
				}
			}
		}

		&.bordered {
			border-bottom: 1px solid $border-color;
		}
	}

	&-body {
		padding: 30px;
		height: 100%;

		@media screen and (max-width: 768px) {
			padding: 10px;
		}
	}

	&-footer {
		border-top: 1px solid $border-color;
		padding: 30px;

		@media screen and (max-width: 991px) {
			padding: 10px 5px 0 5px;
		}
	}
}

.back-img {
	background-image: url(../img/layer3.jpg);
	background-repeat: no-repeat;
	background-position: bottom;
	background-size: contain;
}

.error {
	color: red;
	font-size: 14px;
	margin: 0;
	min-height: 25px;

	span {
		display: block;
	}
}

.button-loader {
	position: absolute;
	top: 50%;
	left: 50%;

	@include transform(translate(-50%, -50%));
}

.breadcrumps {
	list-style-type: none;
	margin: 0;
	padding: 0;

	&-item {
		display: inline-block;
		color: #9296aa;
		font-size: 14px;
		font-weight: 300;
		letter-spacing: -0.22px;
		position: relative;
		margin: 0 4px 0 0;

		@media screen and (max-width: 991px) {
			font-size: 12px;
		}

		&::after {
			content: " •";
		}

		&:last-child::after {
			content: none;
		}
	}
}

.fullheight {
	min-height: 100vh;
}

.search-icon,
.search-close {
	display: inline-block;
	width: 20px;
	height: 20px;
	background-repeat: no-repeat;
	background-size: contain;
	cursor: pointer;
}

.search-icon {
	background-image: url(../img/search.svg);
}

.search-close {
	background-image: url(../img/close_gray.svg);
}

.loader {
	max-height: 100px;
}

.map-object-info {
	width: 200px;
	height: 200px;

	.map-object-info-img {
		max-width: 100%;
		max-height: 100%;
		object-fit: cover;
	}
}

.about-form {
	textarea.form-control-custom {
		height: 350px;
	}

	.form-group-custom {
		height: 400px;
	}
}

.search-not-found {
	color: $theme-blue;
	font-size: 32px;
}

.width20percent {
	width: 20%;
}

.iframe-size {
	width: 100%;
	height: 100vh;
}

.hero-type-buttons {
	margin: 10px;
	font-size: 18px;
	font-weight: bold;
}

.hidden{
	display: none !important;
}

.unStyledList {
	list-style-type: none;
}
