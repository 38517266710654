// btn theme
.btn-theme {
	background-color: $theme-blue;
	color: $white;
	font-weight: 500;
	height: 50px;
	position: relative;

	&:hover {
		background-color: darken($theme-blue, 5%);
		color: $white;
	}
}

.label-status {
	padding: 15px 20px;
	margin: 0;
	width: 100%;
	text-align: center;
	border-radius: 4px;
	color: $white;
	font-size: 14px;
	font-weight: 500;
	letter-spacing: -0.5px;
	white-space: nowrap;
}

.label-4 {
	background-color: #1ecab8;
}

.label-5 {
	background-color: #7a899c;
}

.label-2 {
	background-color: #f5a623;
}

.label-3 {
	background-color: #147eb8;
}

.label-1 {
	background-color: #f85868;
}

.label-0 {
	background-color: $theme-blue;
}

.btn-groups {
	list-style-type: none;
	padding: 0;
	margin: 0;

	li {
		display: inline-block;
		border-right: 1px solid $border-color;

		button {
			color: #565d6f;
			font-size: 16px;
			font-weight: 400;
		}
	}

	li:last-child {
		border-right: none;
	}
}

.btn-rounded {
	width: 50px;
	height: 50px;
	display: block;
	line-height: normal;
	margin-right: 4px;

	@extend .d-flex;
	@extend .align-items-center;
	@extend .justify-content-center;

	&:last-child {
		margin-right: 0;
	}

	@include box-shadow(0 2px 10px 0 rgba(0, 0, 0, 0.15));
}

.btn-theme-default {
	padding: 15px 20px;
	border-radius: 5px;
	border: 1px solid #d0d5da;

	span {
		margin-right: 10px;
		display: inline-block;
	}
}

.btn-back {
	height: 50px;
	display: flex;
	align-items: center;

	&:focus {
		outline: none;
		box-shadow: none;
	}
}

.btn-remove {
	height: 100%;
	width: 40px;
	background-color: #f74b4b;
	color: #fff;
	border: none;
	font-size: 20px;

	&:focus {
		outline: none;
		box-shadow: none;
	}
}

.btn-print {
	@media screen and (max-width: 991px) {
		display: none !important;
	}
}
