// Sass Variables of project

$black: #000;
$white: #fff;
$theme-blue: #4268ff;
$theme-dark-blue:  #171f47;
$theme-blue-2: #3a4992;
$panel-color: #f5f5fb;
$border-color:  #dadada;

$text-dark-blue: #17233b;

$light-gray: #565d6f;

$font-size: 16px;
