/* Fonts */

@font-face {
	font-family: 'montserrat-bold';
	src: url('../fonts/Montserrat/Montserrat-Bold.otf') format('truetype');
	src: url('../fonts/Montserrat/Montserrat-Bold.otf');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'montserrat-light';
	src: url('../fonts/Montserrat/Montserrat-Light.otf') format('truetype');
	src: url('../fonts/Montserrat/Montserrat-Light.otf');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'montserrat-medium';
	src: url('../fonts/Montserrat/Montserrat-Medium.otf') format('truetype');
	src: url('../fonts/Montserrat/Montserrat-Medium.otf');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'montserrat-semibold';
	src: url('../fonts/Montserrat/Montserrat-SemiBold.otf') format('truetype');
	src: url('../fonts/Montserrat/Montserrat-SemiBold.otf');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'montserrat-regular';
	src: url('../fonts/Montserrat/Montserrat-Regular.otf') format('truetype');
	src: url('../fonts/Montserrat/Montserrat-Regular.otf');
	font-weight: normal;
	font-style: normal;
}
