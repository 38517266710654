.gm-svpc {
	display: none;
}

.css-yk16xz-control,
.css-1hwfws3 {
	height: 50px !important;
	// box-sizing: content-box !important;
	border-color: $border-color;
}

.css-1pahdxg-control {
	box-shadow: none !important;
	border: 1px solid $theme-blue !important;
	box-sizing: content-box !important;
}

.css-1pahdxg-control:hover {
	border-color: $theme-blue !important;
}

.css-2b097c-container {
	height: 50px !important;
}

.css-1okebmr-indicatorSeparator {
	display: none;
}

.css-1wa3eu0-placeholder {
	color: lighten(#c5c5d4, 8%) !important;
}

.react-datepicker-wrapper {
	display: block !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range {
	background-color: $theme-blue !important;
}

.fc-toolbar.fc-header-toolbar .fc-left {
	@media screen and (max-width: 991px) {
		margin-bottom: 20px !important;
	}
}

.right-banners {
	margin-bottom: 10px;

	img {
		object-fit: cover;
		width: 100%;
		border-radius: 10px;
	}
}

.websites-banner {
	margin: 15px 0 0 15px;

	.jumbotron {
		background: none;
		padding: 10px 0 5px 0;
		margin-left: 5px;
	}
}

.object-images {
	img {
		height: 215px;
		object-fit: cover;
	}
}

.website-banners {
	list-style-type: none;

	@media screen and (max-width: 991px) {
		display: none;
	}

	li {
		margin: 40px 10px 0 0;

		a {
			text-decoration: none;

			img {
				height: 80px;
				object-fit: cover;
			}
		}
	}
}

.owl-stage {
	display: flex;
	align-items: center;
}
